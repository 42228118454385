import type { Education } from "../types";

export const education: Education[] = [
  {
    school: "Flatiron School",
    degree:
      "Full Stack Web Development, Ruby on Rails, and JavaScript Certificate Program",
    graduated: "October 2020",
    location: "Seattle, WA",
    description:
      "Completed Flatiron School's 15-week Software Engineering boot camp, with a focus on Ruby, Ruby on Rails, JavaScript, and React. I built five projects, ranging from a Ruby-based command-line interface to my final solo project built in React/Redux with a Ruby on Rails back end."
  },
  {
    school: "The College of Management Academic Studies, Israel",
    degree: "Bachelor's degree, Media Studies & Management ",
    graduated: "July 2011",
    location: "Israel",
    description: ""
  }
];
