import type { Project } from "../types";

export const projects: Project[] = [
  {
    name: "FollowDoc",
    github: "https://github.com/cohenoa33/follow-doc-frontend",
    youtube: "https://youtu.be/RfApZrbvUic",
    demo: "RfApZrbvUic",
    live: "https://follow-doc.surge.sh/",
    description: [
      "Developed and designed React application to help the user keep track of medical issues and doctor appointments.",
      "Built complex Rails API backend with PostgreSQL using JSON Web Token (JWT) and Bcrypt to establish relationships between users, appointments, and doctors.",
      "Utilized ActiveStorage and AWS to store user files and Google Maps API to display appointment locations.",
      "Created and designed user interface using React and Redux, and CSS for styling."
    ]
  },
  {
    name: "Appointment List",
    github: "https://github.com/cohenoa33/appointments",
    youtube: "https://youtu.be/kYwYuTt2f5w",
    demo: "kYwYuTt2f5w",
    live: "https://cohenoa33.github.io/appointments/",
    description: [
      "Created a Multi-Language Website based on React Hooks and React Context, allows the user to track medical appointments.",
      "Formed a functional search engine that allows users to narrow down their list based on name,  doctor, and other information.",
      "Designed fully responsive user interface using CSS and  SASS."
    ]
  },
  {
    name: "QPQ",
    github: "https://github.com/cohenoa33/qpq-frontend",
    youtube: "https://youtu.be/rYNZHStJLsk",
    live: "https://qpq-client.herokuapp.com/",
    demo: "rYNZHStJLsk",
    description: [
      "Collaborated with three engineers to build React web application allowing users to trade services.",
      "Developed Rails API and PostgreSQL backend with endpoints for users and services.",
      "Produced React Js frontend with CSS to display services information."
    ]
  },
  {
    name: "What Should We Do",
    github: "https://github.com/cohenoa33/what-should-we-do-frontend",
    youtube: "https://youtu.be/ewwRIRbCr8w",
    live: "https://what-sholud-we-do.surge.sh/",
    demo: "ewwRIRbCr8w",
    description: [
      "Created an activity journal to helps users log everywhere they visit and add reviews for those places.",
      "Built a single page application with HTML, CSS, and vanilla JavaScript.",
      "Generated Rails API backend to track activities and users."
    ]
  },
  {
    name: "Favfest",
    github: "https://github.com/cohenoa33/favfest",
    youtube: "https://youtu.be/VXQRUEcJHs0",
    live: "https://favfest.herokuapp.com/",
    demo: "VXQRUEcJHs0",
    description: [
      "Built Rails Application provides users with information about music festivals, allowing an interactive diary.",
      "Utilized MVC structure and RESTful conventions, HTML Forms, ActiveRecord Models, and Relationship using Postgres Database. Added custom validations to increase security.",
      "Embedded seeded YouTube links that rendered fully interactive videos on rendered pages."
    ]
  }
];
