import type { Skill } from "../types";

export const skills: Skill[] = [
  {
    name: "ActiveRecord"
  },
  {
    name: "Python"
  },
  {
    name: "Prisma"
  },
  {
    name: "Apollo"
  },
  {
    name: "Node.Js"
  },
  {
    name: "AWS"
  },
  {
    name: "Next.Js"
  },
  {
    name: "CSS"
  },
  {
    name: "HTML"
  },
  {
    name: "JavaScript"
  },
  {
    name: "PostgreSQL"
  },
  {
    name: "GraphQL"
  },
  {
    name: "React"
  },
  {
    name: "Redux"
  },
  {
    name: "Git"
  },
  {
    name: "GitHub"
  },
  {
    name: "Ruby"
  },
  {
    name: "Rails"
  },
  {
    name: "Express"
  },
  {
    name: "JWT"
  },
  {
    name: "TypeScript"
  },
  {
    name: "SQL"
  }
];
